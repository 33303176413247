@font-face {
    font-family: 'Philosopher';
    src: url('./fonts/Philosopher/Philosopher-Regular.ttf') format('truetype');
    /* Replace 'your-font-file-name-1' with the actual name of your first font file */
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Prata rev=1';
    src: url('./fonts/Prata/Prata-Regular.ttf') format('truetype');
    /* Replace 'your-font-file-name-2' with the actual name of your second font file */
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Nasa';
    src: url('./fonts/Nasa.ttf') format('truetype');
    /* Replace 'your-font-file-name-2' with the actual name of your second font file */
    font-weight: normal;
    font-style: normal;
  }
  
  body {
    font-family: 'Philosopher', 'Prata rev=1', sans-serif;
    /* Replace 'Your Font Family Name 1' and 'Your Font Family Name 2' with the names you gave the font families in the @font-face rules */
  }