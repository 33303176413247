@media (max-width: 500px) {
  .title_text {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
  }
}

@media only screen and (min-width: 992px) {
  .test_p_1 {
    font-size: 27px !important;
    line-height: initial !important;
  }
  .test_p_2 {
    font-size: 15px !important;
    line-height: initial !important;
  }

  .test_p_3 {
    font-size: 21px !important;
    line-height: initial !important;
  }
  .test_p_4 {
    font-size: 27px !important;
    line-height: initial !important;
  }
  .test_inner_height {
    height: 250px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .test_p_1 {
    font-size: 27px !important;
    line-height: initial !important;
  }
  .test_p_2 {
    font-size: 14px !important;
    line-height: initial !important;
  }

  .test_p_3 {
    font-size: 21px !important;
    line-height: initial !important;
  }
  .test_p_4 {
    font-size: 27px !important;
    line-height: initial !important;
  }
  .test_inner_height {
    height: 250px !important;
  }
}
